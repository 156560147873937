<template>
  <div class="app-container">
    <a-card class="box-card">
      <a-form ref="goods" :rules="rules" :form="form" :layout="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item
              label="供应商"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol">
              <a-select
                placeholder="选择供应商"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                show-search
                allow-clear
                @search="this.corporationSearch"
                @change="handleCorporationChange"
                v-decorator="['supplier_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]">
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option :key="key" :value="val.id" v-for="(val, key) in this.suppliers" :label="val.name">
                  {{ val.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="条形码"
              prop="name"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}]}]" allow-clear placeholder="条形码"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="商品名称"
              prop="brief"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" allow-clear placeholder="商品名称"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="微信展示名称"
              prop="brief"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input v-decorator="['brief', {rules: [{required: true, message: '必填项，请填写信息'}]}]" allow-clear placeholder="商品名称"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="所属分类"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-cascader
                :options="categoryList"
                placeholder="请选择"
                @change="handleCategoryChange"
                v-decorator="['category', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                :field-names="{ label: 'name', value: 'code', children: 'children' }" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="商品类型">
              <a-select
                style="width: 100%"
                placeholder="选择"
                v-decorator="['goods_type', {rules: [{required: true, message: '必填项，请填写信息'}]}]">
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.goods_type" :label="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="品牌"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol">
              <a-input allow-clear placeholder="品牌" v-decorator="['brand', {rules: [{required: false, message: '必填项，请填写信息'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="序号"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol">
              <a-input-number style="width: 100%" v-decorator="['serial', {initialValue: 1, rules: [{required: false, message: '必填项，请填写信息'}]}]" :min="1" allow-clear placeholder="1"/>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="商品单位">
              <a-select
                style="width: 100%"
                placeholder="选择"
                v-decorator="['unit', {rules: [{required: false, message: '必填项，请填写信息'}]}]">
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit" :label="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="数量"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol">
              <a-input-number style="width: 50%" v-decorator="['count', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" :min="1" allow-clear placeholder="1"/>
              <a-select
                style="width: 50%"
                placeholder="选择单位"
                v-decorator="['unit', {rules: [{required: false, message: '必填项，请填写信息'}]}]">
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit" :label="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="成本价">
              <a-input-number
                style="width: 70%"
                v-decorator="['cost_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="0.00"
                :precision="2"
                :min="0">
                <template slot="append">元</template>
              </a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="建议售价">
              <a-input-number
                style="width: 70%"
                placeholder="0.00"
                :precision="2"
                :min="0"
                v-decorator="['suggested_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
              >
                <template slot="append">元</template>
              </a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="原价">
              <a-input-number
                style="width: 70%"
                v-decorator="['tag_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="0.00"
                :precision="2"
                :min="0">
                <template slot="append">元</template>
              </a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="现价">
              <a-input-number
                style="width: 70%"
                placeholder="0.00"
                :precision="2"
                :min="0"
                v-decorator="['price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
              >
                <template slot="append">元</template>
              </a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="商品图片"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-upload
                v-decorator="['picture', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                name="file"
                accept="image/png, image/jpeg, image/jpg"
                :action="actionUrl"
                list-type="picture-card"
                :file-list="fileList"
                :data="new_multipart_params"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    上传
                  </div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="商品滚动图"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-upload
                v-decorator="['gallery', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                name="file"
                accept="image/png, image/jpeg, image/jpg"
                :action="actionUrl"
                list-type="picture-card"
                :file-list="galleryList"
                :data="gallery_new_multipart_params"
                :before-upload="beforeGoodsGalleryUpload"
                @preview="handlePreview"
                @change="handleGoodsGalleryChange"
              >
                <div v-if="galleryList.length < 6">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    上传
                  </div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              v-if="false"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="是否上架">
              <a-switch v-decorator="['is_sale', {initialValue: false, rules: [{required: false, message: '必填项，请填写信息'}]}]"/>
            </a-form-item>
            <a-form-item
              :label-col="buttonItemLayout.labelCol"
              :wrapper-col="buttonItemLayout.wrapperCol"
              label="状态设置">
              <div style="">
                <div>
                  <span style="margin-right: 10px;">是否上架</span><a-switch v-decorator="['is_sale', {initialValue: false, rules: [{required: false, message: '必填项，请填写信息'}]}]"/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span style="margin-right: 10px;">首页展示</span><a-switch v-decorator="['show_on_index', {initialValue: false, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span style="margin-right: 10px;">是否热销</span><a-switch v-decorator="['is_hot', {initialValue: false, rules: [{required: false, message: '必填项，请填写信息'}]}]"/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span style="margin-right: 10px;">是否新品</span><a-switch v-decorator="['is_new', {initialValue: false, rules: [{required: false, message: '必填项，请填写信息'}]}]"/>
                </div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="首页展示">
              <a-switch v-decorator="['show_on_index', {initialValue: false, rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="是否热销">
              <a-switch v-decorator="['is_hot', {initialValue: false, rules: [{required: false, message: '必填项，请填写信息'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="false">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="是否新品">
              <a-switch v-decorator="['is_new', {initialValue: false, rules: [{required: false, message: '必填项，请填写信息'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="* 详细介绍"
              :label-col="buttonItemLayout.labelCol"
              :wrapper-col="buttonItemLayout.wrapperCol">
              <a-upload
                v-show="false"
                class="detail_upload"
                name="file"
                multiple
                accept="image/png, image/jpeg, image/jpg"
                :action="actionUrl"
                list-type="picture-card"
                :file-list="detail_icon_url"
                :before-upload="beforeGoodsDetailUrlUpload"
                @preview="handlePreview"
                @change="handleDetailUrlChange"
                :customRequest="detailCustomRequest"
              >
              </a-upload>
              <quill-editor
                v-model="goods.detail"
                ref="myQuillEditor"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)"
                @change="onEditorChange($event)">
              </quill-editor>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <a-row>
          <a-col :span="24"></a-col>
          <a-col :span="6" offset="8" style="text-align: center"><a-button @click="handleCancel">取消</a-button></a-col>
          <a-col :span="6" style="text-align: center"><a-button type="primary" @click="handlePublish">上架</a-button></a-col>
        </a-row>
      </div>
    </a-card>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { publishGoods } from '@/api/applet_goods'
import { MessageBox } from 'element-ui'
import { mapState } from 'vuex'
import TagSelectOption from '@/components/TagSelect/TagSelectOption'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { Quill } from 'vue-quill-editor/dist/ssr'
import request from '@/utils/request'
import {
  goods_category_list
} from '@/api/applet_goods_category'
import axios from 'axios'
import { supplier_list } from '@/api/supplier'
import debounce from 'lodash/debounce'
import { ossToken } from '@/utils/util'

const fonts = ['Sans-Serif', 'SimSun', 'FangSong', 'Serif', 'Monospace']
Quill.imports['formats/font'].whitelist = fonts

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default {
  name: 'GoodsCreate',
  components: { TagSelectOption, quillEditor },
  data () {
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    return {
      formLayout: 'horizontal',
      request: undefined,
      actionUrl: '',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      gallery_new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      detail_icon_url_new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      suppliers: [],
      fileList: [],
      galleryList: [],
      country_icon_url: [],
      detail_icon_url: [],
      detail_pics: [],
      form: this.$form.createForm(this),
      suitGoodsForm: this.$form.createForm(this),
      fetching: false,
      previewVisible: false,
      uploadData: {},
      previewImage: {},
      categoryList: [],
      categoryChooseList: [],
      brandList: [],
      goods: {
        serial: 1,
        is_hot: false,
        is_new: false,
        is_sale: true,
        show_on_index: true,
        category: undefined,
        gallery: []
      },
      rules: {
        goodsSn: [{ required: true, message: '商品编号不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '商品名称不能为空', trigger: 'blur' }, { max: 30, message: '输入超出长度限制' }]
      },
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote', 'code-block'],

              [{ 'header': 1 }, { 'header': 2 }], // custom button values
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
              [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
              [{ 'direction': 'rtl' }], // text direction

              [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
              [{ 'font': fonts }],
              [{ 'align': [] }],

              ['clean'], // remove formatting button
              ['link', 'image']
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  console.log('image2', value)
                  // 调用element的图片上传组件
                  var element = document.querySelector('.detail_upload input')
                  console.log(element)
                  element.click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          },
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
          }
        },
        placeholder: '商品详情',
        theme: 'snow'
      }
    }
  },
  computed: {
    formItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 6 },
          wrapperCol: { span: 14 }
        } : {}
    },
    buttonItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 3 },
          wrapperCol: { span: 18 }
        } : {}
    }
  },
  created () {
    this.loadOssToken()
    this.init()
  },
  methods: {
    onEditorBlur (quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus (quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady (quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange ({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.$emit('change', html)
    },
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const lastName = file.lastModified + '.' + suffix
      this.new_multipart_params.key = file.uid + '_' + lastName
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      return isJpgOrPng && isLt2M
    },
    beforeGoodsDetailUrlUpload (file) {
      console.log('beforeGoodsDetailUrlUpload', file)
      this.detail_icon_url_new_multipart_params.key = file.uid + '_' + file.name
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      if (isJpgOrPng && isLt2M) {
        this.detailCustomRequest(file)
      }
      return isJpgOrPng && isLt2M
    },
    beforeGoodsGalleryUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      const lastName = file.lastModified + '.' + suffix
      this.gallery_new_multipart_params.key = file.uid + '_' + lastName
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      return isJpgOrPng && isLt2M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview && file.originFileObj) {
        file.preview = await getBase64(file.originFileObj, () => {
        })
      }
      console.log('我要预览', file)
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.goods.picture = undefined
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.goods.picture = this.actionUrl + '/' + this.new_multipart_params.key
        const picture = this.actionUrl + '/' + this.new_multipart_params.key
        this.form.setFieldsValue({
          picture: picture
        })
      }
      console.log('goods detail', this.goods)
    },
    handleGoodsGalleryChange ({ fileList, file }) {
      this.galleryList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.goods.gallery.splice(this.goods.gallery.indexOf(file), 1)
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.goods.gallery.push(this.actionUrl + '/' + this.gallery_new_multipart_params.key)
      }
    },
    handleDetailUrlChange ({ fileList, file }) {
      this.detail_icon_url = fileList
      console.log('handleDetailUrlChange', fileList, file)
      // this.detail_icon_url = fileList.filter(item => {
      //   let result = true
      //   console.log('==================', item)
      //   if (this.detail_icon_url.length > 0) {
      //     this.detail_icon_url.forEach(url => {
      //       if (url !== undefined && url.includes(item.uid)) {
      //         result = false
      //         return result
      //       }
      //     })
      //   }
      //   return result
      // }).map((item) => {
      //   if (item.response && file.status === 'done') {
      //     const newFile = file.response.data
      //     console.log('-------------------------', newFile)
      //     newFile.uid = file.uid
      //     newFile.status = file.status
      //     if (newFile.status === 'done') {
      //       const quill = this.$refs.myQuillEditor.quill
      //       // 获取光标所在位置
      //       const length = quill.getSelection().index
      //       // 插入图片，res为服务器返回的图片链接地址
      //       quill.insertEmbed(length, 'image', this.actionUrl + '/' + file.uid)
      //       // 调整光标到最后
      //       quill.setSelection(length + 1)
      //     }
      //     return newFile
      //   }
      //   return item
      // })

      if (file.status === 'removed') {
        this.goods.detail_icon_url = undefined
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        // this.goods.detail_icon_url.push(this.actionUrl + '/' + this.detail_icon_url_new_multipart_params.key)
        // 获取富文本组件实例
        const quill = this.$refs.myQuillEditor.quill
        // 如果上传成功
        if (file.status === 'done') {
          // 获取光标所在位置
          const length = quill.getSelection().index
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, 'image', this.actionUrl + '/' + this.detail_icon_url_new_multipart_params.key)
          // 调整光标到最后
          quill.setSelection(length + 1)
        } else {
          // 提示信息，需引入Message
          this.$message.error('图片插入失败！')
        }
      }
    },
    ...mapState({ IsPublicWarehouse: state => state.corporationConfig.IsPublicWarehouse }),
    loadOssToken () {
      ossToken().then(res => {
        this.new_multipart_params = res.multipart_params
        this.actionUrl = res.actionUrl
        this.gallery_new_multipart_params = Object.assign({}, this.new_multipart_params)
        this.detail_icon_url_new_multipart_params = Object.assign({}, this.new_multipart_params)
        console.log('自定义OSS返回的数据：', this.new_multipart_params, this.gallery_new_multipart_params, this.detail_icon_url_new_multipart_params)
      })
    },
    init () {
      goods_category_list().then(res => {
        console.log(res)
        const entries = res.data.entries
        const level1 = []
        const level2 = []
        const level3 = []
        entries.forEach((e, i) => {
          if (e.level === 1) {
            level1.push(e)
          } else if (e.level === 2) {
            level2.push(e)
          } else {
            level3.push(e)
          }
        })

        level1.forEach((e, i) => {
          e.children = []
          level2.forEach((e2, i2) => {
            e2.children = []
            if (e.code === e2.code.substr(0, 2)) {
              e.children.push(e2)
            }
            level3.forEach((e3, i3) => {
              if (e2.code === e3.code.substr(0, 4)) {
                e2.children.push(e3)
              }
            })
          })
        })
        res.data.entries = level1
        this.categoryList = res.data.entries
        this.brandList = []
      })
    },
    handleCategoryChange (value) {
      if (value.length !== 3) {
        this.goods.category = undefined
        this.$message.warning('所属分类必须三级')
        return
      }
      this.goods.category = value[2]
    },
    handleCancel () {
      this.$router.back()
      this.previewVisible = false
      this.previewImage = {}
    },
    handlePublish () {
      this.form.validateFields((errors, values) => {
          if (!errors) {
            if (values.category.length !== 3) {
              this.$message.warning('所属分类必须三级')
              return
            }
            values.category = values.category[2]
            if (values.picture.file) {
              const suffix = values.picture.file.name.substring(values.picture.file.name.lastIndexOf('.') + 1)
              values.picture = this.actionUrl + '/' + values.picture.file.uid + '_' + values.picture.file.lastModified + '.' + suffix
            }
            if (this.goods.detail === undefined) {
              this.$message.warning('填写商品详情')
              return
            } else {
              values.detail = this.goods.detail
            }

            if (this.galleryList.length > 0) {
              const gallery = []
              this.galleryList.forEach((item, index) => {
                if (item.url) {
                  gallery.push(item.url)
                } else {
                  gallery.push(this.actionUrl + '/' + item.uid + '_' + item.lastModified + '.' + item.name.substring(item.name.lastIndexOf('.') + 1))
                }
              })
              values.gallery = gallery
            }
            console.log(values)
            values.cost_price = (values.cost_price * 100).toFixed(0)
            values.suggested_price = (values.suggested_price * 100).toFixed(0)
            values.tag_price = (values.tag_price * 100).toFixed(0)
            values.price = (values.price * 100).toFixed(0)
            console.log(values)
            values.code = values.supplier_id + '' + values.barcode
            publishGoods(values).then(response => {
              if (response.code === 1000) {
                this.$router.back()
              }
            }).catch(response => {
              values.cost_price = (values.cost_price / 100).toFixed(0)
              values.suggested_price = (values.suggested_price / 100).toFixed(0)
              values.tag_price = (values.tag_price / 100).toFixed(0)
              values.price = (values.price / 100).toFixed(0)
              MessageBox.alert('业务错误：' + response, '警告', {
                confirmButtonText: '确定',
                type: 'error'
              })
            })
          } else {
            console.log(errors)
          }
      })
    },
    checkIsEmpty () {
      if (!this.goods.brief) {
        this.$message.warning('商品展示名称')
        return false
      }
      if (!this.goods.name) {
        this.$message.warning('仓库内名称')
        return false
      }
      if (this.goods.name.length > 50) {
        this.$message.warning('仓库内名称，长度应小于50个字符')
        return false
      }
      if (!this.goods.cost_price || !this.goods.suggested_price || !this.goods.tag_price || !this.goods.price) {
        this.$message.warning('商品价格')
        return false
      }
      return true
    },
    customRequest (file) {
      setTimeout(() => {
        const fileList = []
        fileList.push({ ...file, status: 'done' })
        fileList.forEach((item) => {
          // 对上传的文件做大小的校验 不能大于20M并且不能超过10张
          if (item.file.size < 1024 * 1027 * 20) {
            const formData = new FormData()
            const name = item.file.name
            const suffix = name.substring(name.lastIndexOf('.') + 1)
            const lastName = item.file.lastModified + '.' + suffix
            const key = item.file.uid + '_' + lastName
            formData.append('policy', this.gallery_new_multipart_params.policy)
            formData.append('OSSAccessKeyId', this.gallery_new_multipart_params.OSSAccessKeyId)
            formData.append('success_action_status', '200')
            formData.append('signature', this.gallery_new_multipart_params.signature)
            formData.append('key', key)
            formData.append('file', item.file)
            request({
              baseURL: item.action,
              method: item.method,
              data: formData
            }).then((Res) => {
              const pic = {
                thumbUrl: this.actionUrl + '/' + key,
                uid: item.file.uid,
                status: 'done',
                name: item.file.name,
                ...item.file
              }
              console.log('=-=-=-=-=', pic)
              this.goods.gallery_list.push(pic)
              this.galleryList.push(pic)
            })
              .catch((err) => {
                console.error(err)
              })
          }
        })
      }, 2000)
    },
    detailCustomRequest (file) {
      setTimeout(() => {
        if (file !== undefined) {
          const fileList = []
          fileList.push({ uid: file.uid, lastModified: file.lastModified, name: file.name, file: file, status: 'done' })
          fileList.forEach((item) => {
            if (item.file.uid === undefined) {
              return
            }
            // 对上传的文件做大小的校验 不能大于20M并且不能超过10张
            const formData = new FormData()
            const name = item.file.name
            const suffix = name.substr(name.lastIndexOf('.') + 1)
            const lastName = item.file.lastModified + '.' + suffix
            const key = item.file.uid + '_' + lastName
            formData.append('policy', this.detail_icon_url_new_multipart_params.policy)
            formData.append('OSSAccessKeyId', this.detail_icon_url_new_multipart_params.OSSAccessKeyId)
            formData.append('success_action_status', '200')
            formData.append('signature', this.detail_icon_url_new_multipart_params.signature)
            formData.append('key', key)
            formData.append('file', item.file)
            if (this.request === undefined) {
              this.request = axios.create({
                // API 请求的默认前缀
                baseURL: this.actionUrl,
                timeout: 10000 // 请求超时时间
              })
            }
            this.request({
              baseURL: this.actionUrl,
              method: 'post',
              data: formData
            }).then((Res) => {
              console.log('上传接口返回', Res)
              const pic = {
                thumbUrl: this.actionUrl + '/' + key,
                uid: item.file.uid,
                status: 'done',
                name: item.file.name,
                ...item.file
              }
              console.log('=-=-=-=-=', pic)
              this.detail_icon_url_new_multipart_params = pic
              const quill = this.$refs.myQuillEditor.quill
                // 获取光标所在位置
                const length = quill.getSelection().index
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', pic.thumbUrl)
                // 调整光标到最后
                quill.setSelection(length + 1)
            })
              .catch((err) => {
                console.error(err)
              }).finally(() => {
              console.log('重新调用获取图片上传key的接口')
              ossToken().then(res => {
                this.detail_icon_url_new_multipart_params = res.multipart_params
                console.log('自定义OSS返回的数据：', this.new_multipart_params, this.gallery_new_multipart_params, this.detail_icon_url_new_multipart_params)
              })
            })
          })
        }
      }, 2000)
    },
    handleCorporationSearch (value) {
      this.fetching = true
      supplier_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.suppliers = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      }).finally(() => {
        this.fetching = false
      })
    },
    handleCorporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleCorporationSearch(value)
      }
    }
  }
}
</script>
<style>
@import "../../../assets/font/font.css";

.ql-editor {
  min-height: 100px;
}

.a-card {
  margin-bottom: 10px;
}

.a-tag + .a-tag {
  margin-left: 10px;
}

.input-new-keyword {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.avatar-uploader .a-upload {
  width: 145px;
  height: 145px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .a-upload:hover {
  border-color: #20a0ff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 145px;
  height: 145px;
  display: block;
}

.op-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/*谷歌*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/*火狐*/
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
